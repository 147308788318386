import "./FrameComponent4.css";
import { useState,useMemo } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUser,faCamera,faPalette,faTimes,faFile,faSquare,faCircle} from '@fortawesome/free-solid-svg-icons';
import Switch from '@mui/material/Switch';

import axios from 'axios'
const label = { inputProps: { 'aria-label': 'Switch demo' } };
const FrameComponent4 = ({ 
  photoWidth,
  photoPadding,
  photoWidth1,
  photoPadding1,
  cropperValues , handleInputChange,imgUrl,handleExtendResult,handleExtendLoad
}) => {
  const [locked,setLocked]=useState(false)
  const [result, setResult] = useState(null);
 const [alertcreate,setalertcreate]=useState("");
  const [prompt, setPrompt] = useState("");// tick
  const [checked, setChecked] = useState(false);
  const [negativePrompt, setNegativePrompt] = useState("");

  const handlelogut=()=>{
    localStorage.clear();
    window.location.reload();
  }
  const handleSubmit = async () => {

    setalertcreate("");
    if(prompt.length<=2)
      {
        setalertcreate('Please enter aleast three characters !')
        return ;
      }
     
      handleExtendLoad()
      setLocked(true)
   
    try {
      const email=localStorage.getItem("email")||false;

      
      const formData = new FormData();
      formData.append('prompt', prompt);
      formData.append('negative_prompt', negativePrompt);
      formData.append('left_percentage', cropperValues.left);
      formData.append('right_percentage', cropperValues.right);
      formData.append('top_percentage', cropperValues.top);
      formData.append('bottom_percentage', cropperValues.bottom);
      formData.append('imgUrl',imgUrl)
      formData.append('email',email)

  
      

     if(email){
     
      const response = await axios.post(
        'https://stock-backend.modelverse.in/extend',
        formData
      ,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      );

      
      setLocked(false)
      console.log(response.data)
    
      handleExtendResult(response.data)
    }else{
      handlelogut()
    }
        
    } catch (error) {
      // console.log(error)
      function myFunction() {
        window.location.reload();
      }
      
      setTimeout(myFunction, 5000);

      
      if (error.response && error.response.status === 401) {
        // Unauthorized access, perform logout action
        handlelogut();
      }
      if (error.response && error.response.status === 402) {
        // Unauthorized access, perform logout action
        setalertcreate("Payment required !");
      }
      // console.log("error",error)
    }
    setLocked(false)
    handleExtendLoad()

  };


  const handleChange = () => {
    setChecked((prev) => !prev);
  };


  return (
    <div className="frame-parent11">
    {result && (
        <div>
          <h3>Extension Result</h3>
          <p>Generation Request ID: {result.generation_request_id}</p>
        </div>
      )}
      <div className="frame-parent12">
        <div className="refine-group">
          <div className="refine6">Extend</div>
          <div className="brush-over-any1">
          Extend the image from all four sides to add borders or increase dimensions.
          </div>
        </div>

        <div className="extend-inp">
                 <div className="first-btn">
                     <label htmlFor="first-1">Top : </label>
                     <input type='number'  id='first-1' name='top' placeholder='...' className="btn1-ext" min="0" max="2000" value={cropperValues.top} onChange={handleInputChange}/>
                 </div>
                 <div className="second-btn">
                     <label htmlFor="second-2">Bottom : </label>
                     <input type='number' id='second-2' name='bottom' placeholder='...' className="btn1-ext" min="0" max="2000" value={cropperValues.bottom} onChange={handleInputChange}/>
                 </div>
                 <div className="third-btn">
                     <label htmlFor="third-3">Left : </label>
                     <input type='number' id='third-3' name='left' placeholder='...' className="btn1-ext" min="0" max="2000" value={cropperValues.left} onChange={handleInputChange}/>
                 </div>
                 <div className="fourth-btn">
                     <label htmlFor="fourth-4">Right : </label>
                     <input type='number' id='fourth-4' name='right' placeholder='...' className="btn1-ext" min="0" max="2000" value={cropperValues.right} onChange={handleInputChange} />
                 </div>
        </div>
      
      </div>
      <h2 className="brush-over-any1">{alertcreate}</h2>
      <div className="prompt-required-group">
        <div className="prompt-required1">
          <span className="prompt2">Prompt (</span>
          <span className="required1">required</span>
          <span className="span1">)</span>
        </div>
        <div className="frame-parent13">
          <textarea
            className="frame-textarea"
            placeholder="Describe image you want to generate"
            rows={6}
            cols={15}
            style={{width: '320px',
                    resize: 'none',
                   height: '100px'}}
            value={prompt} onChange={(e) => setPrompt(e.target.value)}
          />
          <div className="toggle-parent">
           
            <Switch {...label} checked={checked} onChange={handleChange} />
            <div className="negative-prompt-container" style={{marginTop:'6px' , display:!checked?'flex':'none' }}>
              <div className="negative-prompt1">Negative Prompt</div>
            </div>
            <div className="info-wrapper" style={{marginTop:'6px',display:!checked?' flex':'none'}}>
              <img
                className="info-icon1"
                loading="lazy"
                alt=""
                src="/info.svg"
              />
            </div>
            <input style={{visibility:checked?'visible':'hidden'}}  className='inp-1' placeholder='Describe that you dont want in the image' value={negativePrompt} onChange={(e) => setNegativePrompt(e.target.value)}/>

          </div>
        </div>
        

        <button className="sparkle-group" onClick={handleSubmit} disabled={locked}>
          <img className="sparkle-icon2" alt="" src="/sparkle1.svg" />
          <div className="generate2">Generate</div>
        </button>
      </div>
    </div>
  );
};

export default FrameComponent4;
