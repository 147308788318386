

import React, { useEffect, useState } from "react";
import { ReactComponent as CaretLeft } from "./assets/caretleft.svg";
import { ReactComponent as Filter } from "./assets/filter.svg";
import "./History.css";
import axios from 'axios';
import Refine from "./Refine";
import Extend from "./Extend";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

const History = ({ handleBackResult,handleExtendResult,handleRefineResult }) => {
  const [page, setPage] = useState(1);
  const [results, setResults] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [activeImageIndex, setActiveImageIndex] = useState(null);

  const [toggleRefine,setToggleRefine]=useState(false)
  const [toggleExtend,setToggleExtend]=useState(false)
  const [popUpTrigger,setPopUpTrigger]=useState(false)

  const [imgUrlCard,setImgUrlCard]=useState('')
  const handleImgUrlCard=(data)=>{
   
     setImgUrlCard(data)
  }

  const handleBackResult1=()=>{
    setToggleRefine(false)
    setToggleExtend(false)
    // setToggleHistory(false)
  }

  const handleToggleExtend1=(url)=>{
    handleImgUrlCard(url)
    setToggleExtend(!toggleExtend)
  }

  const handleToggleRefine1=(url)=>{
    handleImgUrlCard(url)
    setToggleRefine(!toggleRefine)
    
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getHistory();
  }, []);

  const getHistory = async () => {
    setIdx(idx+1)
    const email = localStorage.getItem("email")||false;
    try {
      if(email){
      let response = await axios.get(`https://stock-backend.modelverse.in/history`, {
        params: { email, page }
      });
      if (response.data.length > 0) {
        setResults(prevResults => [...prevResults, ...response.data]);
        setPage(prevPage => prevPage + 1);
      } else {
        setHasMore(false); // No more data to fetch
      }
    }else{
      handlelogut()
    }
    } catch (error) {
      // console.log(error);
    }
  };

  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  };

  const handleImageClick = (index,url) => {
    setActiveImageIndex(index === activeImageIndex ? null : index);
    handleImgUrlCard(url)
    setPopUpTrigger(true)
  };

  const handleCross1=()=>{
    setPopUpTrigger(!popUpTrigger)
}

 
  const handleDownload = async (url) => {
   
    try {
      const response = await axios.get(url, {
        responseType: 'blob', // Set the response type to blob
      });
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'image.jpg'; // Set the desired filename here
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      // console.error('Error downloading image:', error);
    }
  };

  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const [idx,setIdx]=useState(-1)
  

  useEffect(() => {
    if(results.length>0){
    const img = new Image();
    img.src = `https://stock-backend.modelverse.in/images/${results[idx*4].image_filename}`;

    img.onload = () => {
      const width = img.width;
      const height = img.height;
      setImageDimensions({ width, height });
      
    };
  }
  }, [results]);



  const updateStyle = (newWidth, newHeight) => {
    if (idx >= 0) {
      setImageStyle(prevStyles => {
        if (!Array.isArray(prevStyles)) {
          prevStyles = []; // Ensure prevStyles is an array
        }
        const updatedStyles = [...prevStyles];
        updatedStyles[idx] = { width: newWidth, height: newHeight };
        return updatedStyles;
      });
    }
  };


  const [imageStyle, setImageStyle] = useState([{
    width: '680px',
    height: '680px',
    overflow: 'visible'
  }]);

  const [imageCardStyle,setImageCardStyle]=useState({})

  const checkAspect1 = () => {
    if(idx>=0){
    if (imageDimensions.height === 612 && imageDimensions.width === 612) {
      //1:1

      updateStyle('360px','360px')
      
    }else if (imageDimensions.height === 344 && imageDimensions.width === 612) {
      // 16:9
      
      updateStyle('360px','201px')
    }else if (imageDimensions.height === 612 && imageDimensions.width === 459) {
      //3:4
     
      updateStyle('360px','480px')
    }else if (imageDimensions.height === 459 && imageDimensions.width === 612) {
      //4:3
      
      updateStyle('360px','270px')

    }else if (imageDimensions.height === 612 && imageDimensions.width === 344) {
      //9:16
      updateStyle('360px','640px')
    }else{
      setImageStyle({
        ...imageStyle, // Spread the existing properties
        width: `${imageDimensions.width}px`,
        height: `${imageDimensions.height}px`
      });
    }
  }
  };

 

  useEffect(() => {
    checkAspect1();
  }, [imageDimensions]);

  return (
    <>
     {popUpTrigger&&<div className="pop-Up-cont">
              
              <img style={{borderRadius:'17px'}}  src={imgUrlCard} alt={`Image-pop-up`} />
              <FontAwesomeIcon icon={faTimes} size='2x'  className='cross-btn' onClick={handleCross1}/>

      </div>}

     { !popUpTrigger && !toggleRefine && !toggleExtend && <div className="history">
      <div className="frame">
        <div className="div">
          <div className="frame-2" onClick={handleBackResult}>
            <CaretLeft className="caret-left" />
            <div className="text-wrapper">Back to result</div>
          </div>
          <div className="text-wrapper-2">Recent History</div>
        </div>
        <div className="frame-3">
          {results.length > 0 && results.map((result, index) => (
            
            index % 4 === 0 && (
              <div className="frame-4" key={index}>
                <div className="frame-5">
                  <div className="frame-6">
                    <div className="frame-7">
                      <div className="text-wrapper-3">{formatDate(results[index].created_at)}</div>
                      <p className="p">
                        Prompt: {results && results.length>0 ? results[index].prompt :'Explore our curted gallery of AI images and try the prompts used to generate them.'}
                      </p>
                    </div>
                    <Filter
                      className="filter-instance"
                      color="url(#paint0_linear_299_655)"
                      stroke="url(#paint1_linear_299_655)"
                      onClick={handleBackResult}
                    />
                  </div>
                  <div className="frame-8" style={{ position: 'relative' }}>
                  <div className={`img32`} style={imageStyle[index/4]}>
                    <div className="overlay" ></div>
                    <img
                      className={`rectangle1 `}
                      style={imageStyle[index/4]}
                      src={`https://stock-backend.modelverse.in/images/${results[index].image_filename}`}
                      alt={`Image ${index}`}
                      onClick={() => handleImageClick(index,`https://stock-backend.modelverse.in/images/${results[index].image_filename}`)}
                    />
                    <div className="customization1">
                      <div className="customize61" onClick={()=>{handleToggleRefine1(`https://stock-backend.modelverse.in/images/${results[index].image_filename}`)}}>
                        <div className="vector-wrapper1">
                          <img
                            className="vector-icon91"
                            loading="lazy"
                            alt=""
                            src="/vector.svg"
                          />
                        </div>
                        <div className="customize7 txt-his">Refine</div>
                      </div>
                      <div className="variation31" onClick={()=>{handleToggleExtend1(`https://stock-backend.modelverse.in/images/${results[index].image_filename}`)}}>
                        <div className="variation-container1">
                          <img
                            className="vector-icon101"
                            loading="lazy"
                            alt=""
                            src="/vector-1.svg"
                          />
                        </div>
                        <div className="variations3 txt-his">Extend</div>
                      </div>
                      <div className="download61" onClick={()=>{handleDownload(`https://stock-backend.modelverse.in/images/${results[index].image_filename}`) }}>
                        <div className="download-container1">
                          <div className="arrowsquaredown3">
                            <img
                              className="vector-icon111"
                              loading="lazy"
                              alt=""
                              src="/vector-2.svg"
                            />
                          </div>
                        </div>
                        <div className="download7 txt-his">Download</div>
                      </div>
                    </div>
                  </div>

                  <div className={`img32`} style={imageStyle[index/4]}>
                  <div className="overlay" ></div>
                    <img
                      className={`rectangle1 `}
                      style={imageStyle[index/4]}
                      src={`https://stock-backend.modelverse.in/images/${results[index + 1].image_filename}`}
                      alt={`Image ${index + 1}`}
                      onClick={() => handleImageClick(index + 1,`https://stock-backend.modelverse.in/images/${results[index + 1].image_filename}`)}
                    />

<div className="customization1" >
                      <div className="customize61" onClick={()=>{handleToggleRefine1(`https://stock-backend.modelverse.in/images/${results[index+1].image_filename}`)}}>
                        <div className="vector-wrapper1">
                          <img
                            className="vector-icon91"
                            loading="lazy"
                            alt=""
                            src="/vector.svg"
                          />
                        </div>
                        <div className="customize7 txt-his">Refine</div>
                      </div>
                      <div className="variation31" onClick={()=>{handleToggleExtend1(`https://stock-backend.modelverse.in/images/${results[index+1].image_filename}`)}}>
                        <div className="variation-container1">
                          <img
                            className="vector-icon101"
                            loading="lazy"
                            alt=""
                            src="/vector-1.svg"
                          />
                        </div>
                        <div className="variations3 txt-his">Extend</div>
                      </div>
                      <div className="download61"  onClick={()=>{handleDownload(`https://stock-backend.modelverse.in/images/${results[index+1].image_filename}`) }}>
                        <div className="download-container1">
                          <div className="arrowsquaredown3">
                            <img
                              className="vector-icon111"
                              loading="lazy"
                              alt=""
                              src="/vector-2.svg"
                            />
                          </div>
                        </div>
                        <div className="download7 txt-his">Download</div>
                      </div>
                    </div>


                  </div>

                  <div className={`img32 `} style={imageStyle[index/4]}>
                  <div className="overlay" ></div>
                    <img
                      className={`rectangle1 `}
                      style={imageStyle[index/4]}
                      src={`https://stock-backend.modelverse.in/images/${results[index + 2].image_filename}`}
                      alt={`Image ${index + 2}`}
                      onClick={() => handleImageClick(index + 2,`https://stock-backend.modelverse.in/images/${results[index + 2].image_filename}`)}
                    />

<div className="customization1" >
                      <div className="customize61" onClick={()=>{handleToggleRefine1(`https://stock-backend.modelverse.in/images/${results[index+2].image_filename}`)}}>
                        <div className="vector-wrapper1">
                          <img
                            className="vector-icon91"
                            loading="lazy"
                            alt=""
                            src="/vector.svg"
                          />
                        </div>
                        <div className="customize7 txt-his">Refine</div>
                      </div>
                      <div className="variation31" onClick={()=>{handleToggleExtend1(`https://stock-backend.modelverse.in/images/${results[index+2].image_filename}`)}}>
                        <div className="variation-container1">
                          <img
                            className="vector-icon101"
                            loading="lazy"
                            alt=""
                            src="/vector-1.svg"
                          />
                        </div>
                        <div className="variations3 txt-his">Extend</div>
                      </div>
                      <div className="download61"  onClick={()=>{handleDownload(`https://stock-backend.modelverse.in/images/${results[index+2].image_filename}`) }}>
                        <div className="download-container1">
                          <div className="arrowsquaredown3">
                            <img
                              className="vector-icon111"
                              loading="lazy"
                              alt=""
                              src="/vector-2.svg"
                            />
                          </div>
                        </div>
                        <div className="download7 txt-his">Download</div>
                      </div>
                    </div>


                  </div>

                  <div className={`img32 `} style={imageStyle[index/4]}>
                  <div className="overlay" ></div>
                    <img
                      className={`rectangle1 `}
                      style={imageStyle[index/4]}
                      src={`https://stock-backend.modelverse.in/images/${results[index + 3].image_filename}`}
                      alt={`Image ${index + 3}`}
                      onClick={() => handleImageClick(index + 3,`https://stock-backend.modelverse.in/images/${results[index + 3].image_filename}`)}
                    />
                   <div className="customization1" >
                      <div className="customize61" onClick={()=>{handleToggleRefine1(`https://stock-backend.modelverse.in/images/${results[index+3].image_filename}`)}}>
                        <div className="vector-wrapper1">
                          <img
                            className="vector-icon91"
                            loading="lazy"
                            alt=""
                            src="/vector.svg"
                          />
                        </div>
                        <div className="customize7 txt-his">Refine</div>
                      </div>
                      <div className="variation31" onClick={()=>{handleToggleExtend1(`https://stock-backend.modelverse.in/images/${results[index+3].image_filename}`)}}>
                        <div className="variation-container1">
                          <img
                            className="vector-icon101"
                            loading="lazy"
                            alt=""
                            src="/vector-1.svg"
                          />
                        </div>
                        <div className="variations3 txt-his">Extend</div>
                      </div>
                      <div className="download61"  onClick={()=>{handleDownload(`https://stock-backend.modelverse.in/images/${results[index+3].image_filename}`) }}>
                        <div className="download-container1">
                          <div className="arrowsquaredown3">
                            <img
                              className="vector-icon111"
                              loading="lazy"
                              alt=""
                              src="/vector-2.svg"
                            />
                          </div>
                        </div>
                        <div className="download7 txt-his">Download</div>
                      </div>
                    </div>


                  </div>
                    
                  </div>
                </div>
              </div>
            )
          ))}
          {(results.length === 0 || !hasMore) && <p>No Image Found Please Generate More !</p>}
          {hasMore && (
            <div className="frame-2">
              <div className="text-wrapper-4" onClick={getHistory}>Load More</div>
            </div>
          )}
        </div>
      </div>
    </div>}

    {toggleRefine && <Refine  handleRefineResult={handleRefineResult} handleBackResult={handleBackResult1} imgUrlCard={imgUrlCard} handleDownload={handleDownload}/>}
    {toggleExtend && <Extend handleExtendResult={handleExtendResult} handleBackResult={handleBackResult1} imgUrlCard={imgUrlCard} handleDownload={handleDownload}/>}
    </>
  );
};

export default History;
