import FrameComponent2 from "../components/FrameComponent2";
import Prompt1 from "../components/Prompt1";
import "./SearchResult.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUser,faCamera,faPalette,faTimes,faFile,faSquare,faCircle} from '@fortawesome/free-solid-svg-icons';

import Switch from '@mui/material/Switch';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import axios from "axios";
import React,{useState,useEffect} from "react";
import Refine from "./Refine";
import Extend from "./Extend";
import History from "./History";
import Spinner from "./Spinner";

const SearchResult = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(()=>{
       getLatest()

  },[])


  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });




  const getLatest = async () => {
    // console.log("latest called!")
    const email = localStorage.getItem("email")||false;
    try {
      if(email){
      let response = await axios.get(`https://stock-backend.modelverse.in/latest`, {
        params: {email}
      });
      if (response.data.length > 0) {
        // console.log(response.data)
          setImages(response.data)
          setToggle(true)
      } 
    }else{
       handlelogut()
    }
    } catch (error) {
      // console.log(error);
    }
  };

  const [imgUrlCard,setImgUrlCard]=useState('')
  
   
  const [toggleHistory,setToggleHistory]=useState(false)
  const [toggleRefine,setToggleRefine]=useState(false)
  const [toggleExtend,setToggleExtend]=useState(false)

  const [toggle,setToggle]=useState(false); // when we serach result it will remove those static images

  const [mediaType, setMediaType] = useState("photography");
  const [aspectRatio, setAspectRatio] = useState("1:1");
  const [mood, setMood] = useState("natural");
  const [prompt, setPrompt] = useState("");
  const [images, setImages] = useState([]);
  const [negativePrompt, setNegativePrompt] = useState("");
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [imageButtonsVisible, setImageButtonsVisible] = useState(false);
  const [popUpTrigger,setPopUpTrigger]=useState(false)
  const [popUpImgUrl,setPopUpImgUrl]=useState("")
  const [checked, setChecked] = useState(false);
  const [locked,setLocked]=useState(false)
  const [loading,setLoading]=useState(false)
  const[alertcreate,setalertcreate]=useState("");
  
  useEffect(() => {
    if(images.length>0){
    const img = new Image();
    img.src = `https://stock-backend.modelverse.in/images/${images[0].image_filename}`;

    img.onload = () => {
      const width = img.width;
      const height = img.height;
      setImageDimensions({ width, height });
      // console.log(width,height,width/height)
    };
  }
  }, [images]);


  const [boxHeight, setBoxHeight] = useState("80vh"); // State for box height
  
  const [selectedImageUrl, setSelectedImageUrl] = useState("");

  const [refineClicked, setRefineClicked] = useState(false); // State to track if Refine button is clicked

   
  const handleImgUrlCard=(data)=>{
    // setPopUpTrigger(true)
     setImgUrlCard(data)
  }

  const handlePopUpTrigger=()=>{
    setPopUpTrigger(true)
  }

  const handlelogut=()=>{
    localStorage.clear();
    window.location.reload();
  }
   const handleExtendResult=(data)=>{
  
        setImages(data);
        handleBackResult()
   }

   const handleRefineResult=(data)=>{

        setImages(data);
        handleBackResult()
   }

    const handleChange = () => {
      setChecked((prev) => !prev);
    };

    const handleMediaType=(e)=>{
     
      setMediaType(e.currentTarget.value)
    }

    const handleAspectRatio=(e)=>{
      
      setAspectRatio(e.currentTarget.value)
    }

    const handleMood=(e)=>{
      
         setMood(e.currentTarget.value)
    }

    const sendDataToServer = async () => {
     setalertcreate("");
      if(prompt.length<=2)
        {
          setalertcreate('Please enter aleast three characters !')
          return ;
        }
        setToggle(true);
        setLocked(true)
        setLoading(true)



const email=localStorage.getItem("email")||false;

      const data = {
        prompt: prompt,
        negative_prompt: negativePrompt,
        aspect_ratio: aspectRatio,
        media_type: mediaType,
        mood: mood,
        email:email,
        notes: "string" // Placeholder value, you can replace it with actual notes if needed
      };
   
 
      
      try {

        if(email){
        const response = await axios.post("https://stock-backend.modelverse.in/createimage", data);
        
        setImages(response.data);
       
        
        
        setLoading(false)
        }else{
          handlelogut()
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Unauthorized access, perform logout action
          handlelogut();
        }
        if (error.response && error.response.status === 402) {
          // Unauthorized access, perform logout action
          setalertcreate("Payment required !");
        }
        // console.error("Error sending data:", error);
      }
      setLoading(false)
      setLocked(false)
    };


    // const handleImageClick = (index,imageUrl) => {
    //   setPopUpTrigger(!popUpTrigger)
    //   setPopUpImgUrl(imageUrl)
    //   if (selectedImageIndex === index) {
    //     // If the clicked image is already selected, toggle the visibility of buttons
    //     setImageButtonsVisible(!imageButtonsVisible);
  
    //   } else {
    //     // If a new image is clicked, show its buttons and hide buttons of previously selected image
    //     setSelectedImageIndex(index);
    //     setImageButtonsVisible(true);
    //   }
    // };

    const handleDownload = async (url) => {
   
      try {
        const response = await axios.get(url, {
          responseType: 'blob', // Set the response type to blob
        });
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'image.jpg'; // Set the desired filename here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        // console.error('Error downloading image:', error);
      }
    };


    const handleRefineClick = (imageUrl) => {
      setPopUpTrigger(!popUpTrigger)
      setSelectedImageUrl(imageUrl); // Set the selected image URL
      setRefineClicked(true); // Set refineClicked state to true when Refine button is clicked
    };
    
    const handleBack = () => {
      setSelectedImageUrl(""); // Reset the selected image URL when going back
      setRefineClicked(false); // Set refineClicked state to false when Back button is clicked
    };
    
  
    const handleCross=()=>{
           setPopUpTrigger(!popUpTrigger)
    }


 
  const handleToggleExtend=(url)=>{
    handleImgUrlCard(url)
    setToggleExtend(!toggleExtend)
  }

  const handleToggleRefine=(url)=>{
    handleImgUrlCard(url)
    setToggleRefine(!toggleRefine)
    
  }

  const handleBackResult=()=>{
    setToggleRefine(false)
    setToggleExtend(false)
    setToggleHistory(false)
  }


  const handleToggleHistory=()=>{
    setToggleHistory(!toggleHistory)
  }

  const handleMore=()=>{
    window.scrollTo(0, 0);
  }



  return (
    <>
     {popUpTrigger&&<div className="pop-Up-cont">
              
              <img style={{borderRadius:'17px'}}  src={imgUrlCard} alt={`Image-pop-up`} />
              <FontAwesomeIcon icon={faTimes} size='2x'  className='cross-btn' onClick={handleCross}/>

      </div>}
    
    { !popUpTrigger&& !toggleRefine && !toggleExtend && !toggleHistory && <div className="search-result">
      
      <main className="main">
        <FrameComponent2
          photoWidth="unset"
          photoPadding="var(--padding-10xs-4) var(--padding-10xs-5) var(--padding-10xs-5) var(--padding-10xs-4)"
          photoWidth1="unset"
          photoPadding1="var(--padding-10xs-4) var(--padding-10xs-4) var(--padding-10xs-5) var(--padding-10xs-5)"
          handleToggleHistory={handleToggleHistory} mediaType={mediaType} handleMediaType={handleMediaType} aspectRatio={aspectRatio} handleAspectRatio={handleAspectRatio} mood={mood} handleMood={handleMood}
        />
        <Prompt1 handlePopUpTrigger={handlePopUpTrigger} imageDimensions={imageDimensions} handleToggleHistory={handleToggleHistory} loading={loading} locked={locked} images={images} handleImgUrlCard={handleImgUrlCard} handleToggleExtend={handleToggleExtend}  handleToggleRefine={handleToggleRefine} mediaType={mediaType} aspectRatio={aspectRatio} mood={mood} sendDataToServer={sendDataToServer} prompt={prompt}  setPrompt={setPrompt} toggle={toggle} handleDownload={handleDownload} alertcreate={alertcreate}/>
      </main>
      <div className="more">
        <button className="more-container">
          <div className="generate-more" onClick={handleMore} style={{cursor:'pointer'}} >Generate More</div>
        </button>
      </div>
    </div>}

    {toggleRefine && <Refine  handleRefineResult={handleRefineResult} handleBackResult={handleBackResult} imgUrlCard={imgUrlCard} handleDownload={handleDownload}/>}
    {toggleExtend && <Extend handleExtendResult={handleExtendResult} handleBackResult={handleBackResult} imgUrlCard={imgUrlCard} handleDownload={handleDownload}/>}
    {toggleHistory && <History handleRefineResult={handleRefineResult} handleExtendResult={handleExtendResult} handleBackResult={handleBackResult}/>}
    </>
  );
};

export default SearchResult;
