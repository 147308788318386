import { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import axios from "axios";
const ProtectedRoute = () => {
    const token = localStorage.getItem("userToken");
    
        if (!token){
            return <Navigate to="/login" replace />;
        }
        else{
            return <Outlet />;
}
};

export default ProtectedRoute;
