import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from "./assets/modelverse-logo.png";
import "./login.css";

const Signup = (props) => {
    const BASE_URL = "https://stock-backend.modelverse.in";
    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const[alertcreate,setalertcreate]=useState("");
    const validateUsername = (username, email) => {
        const usernamePattern = /^(?=.*[0-9])[^\s]+$/;
        const emailLocalPart = email.split('@')[0];
        return usernamePattern.test(username) && !username.includes(emailLocalPart);
    };

    const validatePassword = (password) => {
        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return passwordPattern.test(password);
    };

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const handleSendOtp = () => {
        if (!validateEmail(email)) {
            setalertcreate('Please enter a valid email address.');
            return;
        }

        if (!validateUsername(username, email)) {
            setalertcreate('Username must not contain spaces, must include at least one number, and must not contain any part of the email.');
            return;
        }

        if (!validatePassword(password)) {
            setalertcreate('Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.');
            return;
        }

        const userData = { username, email, password };
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(userData),
        };

        fetch(`${BASE_URL}/auth/sign-up`, requestOptions)
            .then((response) => response.json())
            .then((json) => {
                if (json.success === true) {
                    alert(json.message);
                    setOtpSent(true);
                } else {
                    // console.log(json);
                    alert(json.message);
                }
            })
            .catch((error) => {
                // console.error('API Error:', error);
            });
    };

    const handleVerifyOtp = () => {
        const otpData = { email, otp, username, password }; // Include password in otpData
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(otpData),
        };

        fetch(`${BASE_URL}/auth/verify-otp`, requestOptions)
            .then((response) => response.json())
            .then((json) => {
                if (json.success === true) {
                    alert(json.message + " Login Now!");
                    navigate('/login');
                } else {
                    alert(json.message);
                    // console.log(json);
                }
            })
            .catch((error) => {
                // console.error('API Error:', error);
            });
    };

    return (
        <div className='signup-container'>
            <div className='card'>
                <img src={logo} className='logo-image' alt="" />
                <h2>Sign up</h2>
                <h2 className='alert'>{alertcreate}</h2>
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                {!otpSent ? (
                    <button className='login-btn' onClick={handleSendOtp}>Send OTP</button>
                ) : (
                    <>
                        <input
                            type="text"
                            placeholder="Enter OTP"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                        />
                        <button className='login-btn' onClick={handleVerifyOtp}>Verify OTP</button>
                    </>
                )}
                <p className='register-link'>
                    Already have an account. <Link to='/' style={{ color: '#fe5816' }}>Sign In Now</Link>
                </p>
            </div>
        </div>
    );
};

export default Signup;
