

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ImageCard = ({ imageUrl, handleDownload, handleToggleExtend, handleToggleRefine }) => {
  return (
    <div className="customization">
      <div className="customize6" onClick={()=>{handleToggleRefine(imageUrl)}}>
        <div className="vector-wrapper">
          <img className="vector-icon9" loading="lazy" alt="" src="/vector.svg" />
        </div>
        <div className="customize7">Refine</div>
      </div>
      <div className="variation3" onClick={()=>{handleToggleExtend(imageUrl)}}>
        <div className="variation-container">
          <img className="vector-icon10" loading="lazy" alt="" src="/vector-1.svg" />
        </div>
        <div className="variations3">Extend</div>
      </div>
      <div className="download6" onClick={() => { handleDownload(imageUrl) }}>
        <div className="download-container">
          <div className="arrowsquaredown3">
            <img className="vector-icon11" loading="lazy" alt="" src="/vector-2.svg" />
          </div>
        </div>
        <div className="download7">Download</div>
      </div>
    </div>
  );
};

const ImageCardLoop = ({handlePopUpTrigger,imageDimensions, images, handleDownload, handleToggleExtend, handleToggleRefine, handleImgUrlCard }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const handleClick = (index, url) => {
    setSelectedImageIndex(index);
    handleImgUrlCard(url);
    handlePopUpTrigger()
  };

  const [imageStyle, setImageStyle] = useState({
    width: '680px',
    height: '680px',
    overflow: 'visible'
  });

  const [imageCardStyle,setImageCardStyle]=useState({})

  const checkAspect = () => {
    if (imageDimensions.height === 612 && imageDimensions.width === 612) {
      //1:1
      setImageStyle({
        ...imageStyle, // Spread the existing properties
        width: '570px',
        height: '570px'
      });
    }else if (imageDimensions.height === 344 && imageDimensions.width === 612) {
      // 16:9
      setImageStyle({
        ...imageStyle, // Spread the existing properties
        width: '570px',
        height: '319px'
      });
    }else if (imageDimensions.height === 612 && imageDimensions.width === 459) {
      //3:4
      setImageCardStyle({
        ...imageCardStyle, // Spread the existing properties
        justifyContent:'center'
      });
      setImageStyle({
        ...imageStyle, // Spread the existing properties
        width: `${imageDimensions.width}px`,
        height: `${imageDimensions.height}px`
      });
    }else if (imageDimensions.height === 459 && imageDimensions.width === 612) {
      //4:3
      setImageStyle({
        ...imageStyle, // Spread the existing properties
        width: '570px',
        height: '427px'
      });

    }else if (imageDimensions.height === 612 && imageDimensions.width === 344) {
      //3:4
      setImageCardStyle({
        ...imageCardStyle, // Spread the existing properties
        justifyContent:'center'
      });
      setImageStyle({
        ...imageStyle, // Spread the existing properties
        width: `${imageDimensions.width}px`,
        height: `${imageDimensions.height}px`
      });
    }else{
      setImageStyle({
        ...imageStyle, // Spread the existing properties
        width: `${imageDimensions.width}px`,
        height: `${imageDimensions.height}px`
      });
    }
  };

 

  useEffect(() => {
    checkAspect();
  }, [imageDimensions]);


  return (
    <>
      {images && images.map((image, index) => (
        index % 2 === 0 &&
        <div key={index} className="image-cards" style={imageCardStyle}>
          <div className='img31' style={imageStyle}>
            <img className='image31-ch' style={imageStyle} src={`https://stock-backend.modelverse.in/images/${images[index].image_filename}`} alt={index} onClick={() => handleClick(index, `https://stock-backend.modelverse.in/images/${images[index].image_filename}`)}></img>
            <div className="overlay"></div>
            <ImageCard
              imageUrl={`https://stock-backend.modelverse.in/images/${images[index].image_filename}`}
              handleDownload={handleDownload}
              handleToggleExtend={handleToggleExtend}
              handleToggleRefine={handleToggleRefine}
            />
          </div>

          {images[index + 1] && (
            <div className='img31' style={imageStyle}>
              <img className='image31-ch'  style={imageStyle} src={`https://stock-backend.modelverse.in/images/${images[index + 1].image_filename}`} alt={index + 1} onClick={() => handleClick(index + 1, `https://stock-backend.modelverse.in/images/${images[index + 1].image_filename}`)}></img>
              <div className="overlay"></div>
              <ImageCard
                imageUrl={`https://stock-backend.modelverse.in/images/${images[index + 1].image_filename}`}
                handleDownload={handleDownload}
                handleToggleExtend={handleToggleExtend}
                handleToggleRefine={handleToggleRefine}
              />
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default ImageCardLoop;
