import FrameComponent4 from "../components/FrameComponent4";
import "./Extend.css";
import { useState,useEffect } from "react";
import Refine from "./Refine";
import Cropper from "./Cropper";
import Spinner from "./Spinner";

const Extend = ({handleExtendResult,handleBackResult,handleDownload,imgUrlCard,handleToggle,handleBackNew}) => {
  const [toggle1,setToggle1]=useState(false)  // for change it to extend
  const [isMannual,setIsMannual]=useState(false)
  const [extendLoad,setExtendLoad]=useState(false)
  const [block,setBlock]=useState(false)

  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if(imgUrlCard){
    const img = new Image();
    img.src = imgUrlCard;

    img.onload = () => {
      const width = img.width;
      const height = img.height;
      setImageDimensions({ width, height });
    };
  }
  }, [imgUrlCard]);

  useEffect(()=>{
    if(imageDimensions.height!=0 && imageDimensions.width!=0)
      {
          setBlock(true)
      }
  },[imageDimensions])

  
  const handlelogut=()=>{
    localStorage.clear();
    window.location.reload();
  }
  const handleIsMannual=()=>{
    setIsMannual(!isMannual)
  }
   
   const handleToggle1=()=>{
       setToggle1(!toggle1)
     
   }

   const handleBackNew1=()=>{
   
      setToggle1(!toggle1)
      handleBackResult()
      
   }

   const [cropperValues, setCropperValues] = useState({
    top: 0,
    left: 0,
    right: 0, // Initial value for right side
    bottom: 0 // Initial value for bottom side
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Ensure the parsed value is within the range of 0 to 100
    const parsedValue = Math.max(0, Math.min(100, parseInt(value, 10)));
    
    setCropperValues(prevState => ({
      ...prevState,
      [name]: parsedValue,
    }));
    setIsMannual(true)
  };

  const CropHandler = (name, value) => {
    const parsedValue = Math.max(0, Math.min(100, parseInt(value, 10)));
   
    setCropperValues(prevState => ({
      ...prevState,
      [name]: parsedValue,
    }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleExtendLoad=()=>{
    setExtendLoad(!extendLoad)
  }
  


  return (
    <>
    {!toggle1 && <div className="extend">
      <div className="frame-div">
        <button className="caretleft-group">
          <img className="caretleft-icon1" alt="" src="/caretleft.svg" />
         {handleBackNew? (<div className="back-to-result1" onClick={handleBackNew}>Back to result</div>):( <div className="back-to-result1" onClick={handleBackResult}>Back to result</div>)}
        </button>
        <FrameComponent4 
          photoWidth="unset"
          photoPadding="var(--padding-10xs-4) var(--padding-10xs-5) var(--padding-10xs-5) var(--padding-10xs-4)"
          photoWidth1="unset"
          photoPadding1="var(--padding-10xs-4) var(--padding-10xs-4) var(--padding-10xs-5) var(--padding-10xs-5)"
          handleExtendLoad={handleExtendLoad} handleExtendResult={handleExtendResult} cropperValues={cropperValues} handleInputChange={handleInputChange} handleIsMannual={handleIsMannual} imgUrl={imgUrlCard}
         />
      </div>
      
     <div className="frame-parent1">
        <div className="tabs-wrapper">
          <div className="tabs">
            <div className="tab2">
              { handleToggle ? (<div className="refine3" onClick={handleToggle}>Refine</div>):(<div className="refine3" onClick={handleToggle1}>Refine</div>)}
              
            </div>
            <div className="tab3">
              <div className="refine4">Extend</div>
            </div>
          </div>
        </div>
        {extendLoad  && <Spinner left="54%" top="40%" />}
        {!extendLoad &&<div className="image-parent">
          {/* <div className="image1"> */}
            {/* <div className="customize-group">
              <div className="customize2">
                <img className="vector-icon3" alt="" src="/vector.svg" />
                <div className="customize3">Customize</div>
              </div>
              <div className="variation1">
                <img className="vector-icon4" alt="" src="/vector-1.svg" />
                <div className="variations1">Variations</div>
              </div>
              <div className="download2">
                <div className="arrowsquaredown1">
                  <img className="vector-icon5" alt="" src="/vector-2.svg" />
                </div>
                <div className="download3">Download</div>
              </div>
            </div> */}
          {/* </div> */}
          {block && <Cropper imageDimensions={imageDimensions} imageUrl={imgUrlCard} cropperValues={cropperValues} setCropperValues={setCropperValues} CropHandler={CropHandler} isMannual={isMannual} handleIsMannual={handleIsMannual} />}
          {/* <img className='image' src={imgUrlCard} alt='image'></img> */}

          <div className="filter-container1" style={{marginLeft: '50px'}}>
            <div className="filter1" onClick={()=>{handleDownload(imgUrlCard)}}>
              <div className="arrowdown-container">
                <img className="arrowdown-icon1" alt="" src="/arrowdown.svg" />
              </div>
              <div className="photo1">Download</div>
            </div>
          </div>
        </div>}
      </div>
    </div>}
    {toggle1 &&<Refine  handleBackNew1={handleBackNew1} handleToggle1={handleToggle1} handleBackResult={handleBackResult} imgUrlCard={imgUrlCard} handleDownload={handleDownload}/>}
    </>
  );
};

export default Extend;
