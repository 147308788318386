import React, { useState, useRef, useEffect } from 'react';
import './Cropper.css';

const Cropper = ({ imageDimensions,imageUrl,cropperValues, setCropperValues ,CropHandler,isMannual,handleIsMannual }) => {
  // const imageWidth = 590;
  // const imageHeight = 490;

  const [imageWidth,setImageWidth]=useState(imageDimensions.width)
  const [imageHeight,setImageHeight]=useState(imageDimensions.height)

  useEffect(()=>{
         setImageWidth(imageDimensions.width)
         setImageHeight(imageDimensions.height)
  },[imageDimensions])

  const [cropper, setCropper] = useState({
    top: 0,
    left: 0,
    width:imageWidth ,
    height: imageHeight,
  });

  const [tempoCrop,setTempoCrop]=useState({
    top: 0,
    left: 0,
    width: imageWidth,
    height:imageHeight,
  })

  const [cropo,setCropo]=useState({
    top: 0,
    left: 0,
    right: 0, 
    bottom: 0 
  })


  const MainCropperHandler=()=>{

    if(cropperValues.left>cropo.left)
      {
             let val=cropperValues.left-cropo.left;
             setTempoCrop(prevState => ({
              ...prevState,
              left:((-1)*cropperValues.left),
              width:tempoCrop.width+val
            }));
      }

      if(cropperValues.left<cropo.left)
        {
               let val=cropo.left-cropperValues.left;
               setTempoCrop(prevState => ({
                ...prevState,
                left:((-1)*cropperValues.left),
                width:tempoCrop.width-val
              }));
        }
    
        if(cropperValues.right>cropo.right)
          {
                 let val=cropperValues.right-cropo.right;
                 setTempoCrop(prevState => ({
                  ...prevState,
                  width:tempoCrop.width+val
                }));
          }

          if(cropperValues.right<cropo.right)
            {
                   let val=cropo.right-cropperValues.right;
                   setTempoCrop(prevState => ({
                    ...prevState,
                    width:tempoCrop.width-val
                  }));
            }



            if(cropperValues.top>cropo.top)
              {
                     let val=cropperValues.top-cropo.top;
                     setTempoCrop(prevState => ({
                      ...prevState,
                      top:((-1)*cropperValues.top),
                      height:tempoCrop.height+val
                    }));
              }
        
              if(cropperValues.top<cropo.top)
                {
                       let val=cropo.top-cropperValues.top;
                       setTempoCrop(prevState => ({
                        ...prevState,
                        top:((-1)*cropperValues.top),
                        height:tempoCrop.height-val
                      }));
                }
            
                if(cropperValues.bottom>cropo.bottom)
                  {
                         let val=cropperValues.bottom-cropo.bottom;
                         setTempoCrop(prevState => ({
                          ...prevState,
                          height:tempoCrop.height+val
                        }));
                  }
        
                  if(cropperValues.bottom<cropo.bottom)
                    {
                           let val=cropo.bottom-cropperValues.bottom;
                           setTempoCrop(prevState => ({
                            ...prevState,
                            height:tempoCrop.height-val
                          }));
                    }
           
              setCropo(cropperValues)
              setCropper(tempoCrop)

    handleIsMannual()

  }

  useEffect(()=>{
     if(isMannual){
      MainCropperHandler()
     }
  },[cropperValues])

  const canvasRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    drawImage();
  }, [imageUrl]);

  useEffect(() => {
    drawCroppedImage();
  }, [cropper]);

  const CropoHandler = (name, value) => {
    const parsedValue = Math.max(0, Math.min(100, parseInt(value, 10)));

    setCropo(prevState => ({
      ...prevState,
      [name]: parsedValue,
    }));
  };

  const handleDrag = (handle, e) => {
    e.preventDefault();
    const startX = e.clientX;
    const startY = e.clientY;
    const startCropper = { ...cropper };

    const handleMouseMove = (e) => {
      const deltaX = e.clientX - startX;
      const deltaY = e.clientY - startY;
      const newCropper = { ...startCropper };

      if (handle === 'top-left') {
        newCropper.width = Math.max(20, startCropper.width - deltaX);
        newCropper.height = Math.max(20, startCropper.height - deltaY);
        newCropper.top = startCropper.top + (startCropper.height - newCropper.height);
        newCropper.left = startCropper.left + (startCropper.width - newCropper.width);

        
        let val=newCropper.height-imageHeight-cropperValues.bottom;
        CropoHandler('top',val)
        CropHandler('top',val);
        

        let val1=newCropper.width-imageWidth-cropperValues.right;
        CropoHandler('left',val1);
        CropHandler('left',val1);

      } else if (handle === 'top-right') {
        newCropper.width = Math.max(20, startCropper.width + deltaX);
        newCropper.height = Math.max(20, startCropper.height - deltaY);
        newCropper.top = startCropper.top + (startCropper.height - newCropper.height);
        
        let val=newCropper.height-imageHeight-cropperValues.bottom;
        CropoHandler('top',val);
        CropHandler('top',val);

        let val1=newCropper.width-imageWidth-cropperValues.left;
        CropoHandler('right',val1);
        CropHandler('right',val1);

      } else if (handle === 'bottom-left') {
        newCropper.width = Math.max(20, startCropper.width - deltaX);
        newCropper.height = Math.max(20, startCropper.height + deltaY);
        newCropper.left = startCropper.left + (startCropper.width - newCropper.width);

        let val=newCropper.height-imageHeight-cropperValues.top
        CropoHandler('bottom',val)
        CropHandler('bottom',val)

        let val1=newCropper.width-imageWidth-cropperValues.right;
        CropoHandler('left',val1);
        CropHandler('left',val1);

      } else if (handle === 'bottom-right') {
        newCropper.width = Math.max(20, startCropper.width + deltaX);
        newCropper.height = Math.max(20, startCropper.height + deltaY);

        let val=newCropper.height-imageHeight-cropperValues.top
        CropoHandler('bottom',val)
        CropHandler('bottom',val)

        let val1=newCropper.width-imageWidth-cropperValues.left;
        CropoHandler('right',val1);
        CropHandler('right',val1);


      } else if (handle === 'top') {
        newCropper.height = Math.max(20, startCropper.height - deltaY);
        newCropper.top = startCropper.top + (startCropper.height - newCropper.height);

        let val=newCropper.height-imageHeight-cropperValues.bottom;
        CropoHandler('top',val);
        CropHandler('top',val);

      } else if (handle === 'left') {
        newCropper.width = Math.max(20, startCropper.width - deltaX);
        newCropper.left = startCropper.left + (startCropper.width - newCropper.width);

        let val=newCropper.width-imageWidth-cropperValues.right;
        CropoHandler('left',val);
        CropHandler('left',val);

      } else if (handle === 'right') {
        newCropper.width = Math.max(20, startCropper.width + deltaX);

        let val=newCropper.width-imageWidth-cropperValues.left;
        CropoHandler('right',val);
        CropHandler('right',val);

      } else if (handle === 'bottom') {
        newCropper.height = Math.max(20, startCropper.height + deltaY);

        let val=newCropper.height-imageHeight-cropperValues.top
        CropoHandler('bottom',val)
        CropHandler('bottom',val)
      }

      setCropper(newCropper);
      setTempoCrop(newCropper)
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const drawImage = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const img = new Image();

    img.onload = () => {
      canvas.width = imageWidth;
      canvas.height = imageHeight;
      ctx.drawImage(img, 0, 0, imageWidth, imageHeight);
    };

    img.src = imageUrl;
  };

  const drawCroppedImage = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const img = imageRef.current;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(img, 0, 0, imageWidth, imageHeight);
    ctx.strokeStyle = 'red';
    ctx.lineWidth = 2;
    ctx.strokeRect(cropper.left, cropper.top, cropper.width, cropper.height);
  };

  

  return (
    <div className="container" style={{    marginLeft: '79px'}}>
      <canvas ref={canvasRef}></canvas>
      <img
        src={imageUrl}
        alt="Croppable"
        ref={imageRef}
        style={{ display: 'none' }}
      />
      <div
        className="cropper"
        
        style={{
          top: tempoCrop.top,
          left: tempoCrop.left,
          width: tempoCrop.width,
          height: tempoCrop.height,
        }}
      >
        <div
          className="handle top-left"
          onMouseDown={(e) => handleDrag('top-left', e)}
        ></div>
        <div
          className="handle top-right"
          onMouseDown={(e) => handleDrag('top-right', e)}
        ></div>
        <div
          className="handle bottom-left"
          onMouseDown={(e) => handleDrag('bottom-left', e)}
        ></div>
        <div
          className="handle bottom-right"
          onMouseDown={(e) => handleDrag('bottom-right', e)}
        ></div>
        <div
          className="handle top center-switch-verti"
          onMouseDown={(e) => handleDrag('top', e)}
        ></div>
        <div
          className="handle left center-switch-horiz"
          onMouseDown={(e) => handleDrag('left', e)}
        ></div>
        <div
          className="handle right center-switch-horiz"
          onMouseDown={(e) => handleDrag('right', e)}
        ></div>
        <div
          className="handle bottom center-switch-verti"
          onMouseDown={(e) => handleDrag('bottom', e)}
        ></div>
      </div>
    </div>
  );
};

export default Cropper;
