import { useMemo } from "react";
import "./FrameComponent2.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUser,faCamera,faPalette,faTimes,faFile,faSquare,faCircle} from '@fortawesome/free-solid-svg-icons';

const FrameComponent2 = ({
  photoWidth,
  photoPadding,
  photoWidth1,
  photoPadding1,
  mediaType,
  handleMediaType,
  aspectRatio,
  handleAspectRatio,
  mood,
  handleMood,
  handleToggleHistory
  
}) => {
  const emptyRatioOptionsStyle = useMemo(() => {
    return {
      width: photoWidth,
      padding: photoPadding,
    };
  }, [photoWidth, photoPadding]);

  const divStyle = useMemo(() => {
    return {
      width: photoWidth1,
      padding: photoPadding1,
    };
  }, [photoWidth1, photoPadding1]);

  return (
    <div className="frame-parent3">
      <div className="ai-image-generator-parent">
        <h1 className="ai-image-generator">AI image Generator</h1>
        <div className="preferences-parent">
          <div className="preferences">
            <div className="filter-your-preferences">
              Filter Your preferences
            </div>
            <div className="image-type-parent">
              <div className="image-type">Image type</div>
              <div className="image-type-filters">
                <button className={`filter2 ${mediaType==='photography'?'selected':''}`} value='photography' onClick={(e)=>{handleMediaType(e)}}>
                  
                  <FontAwesomeIcon className="image-icon" alt="" size='xs' icon={faCamera} />
                  <div className="photo2">Photo</div>
                </button>
                <button className={`filter3 ${mediaType==='illustration'?'selected':''}`} value='illustration' onClick={(e)=>{handleMediaType(e)}}>
                 
                  <FontAwesomeIcon className="image-icon" alt="" size='xs' icon={faPalette} />
                  <div className="photo3">Illustrations</div>
                </button>
              </div>
            </div>
            <div className="preferences-child" />
            <div className="aspect-ratio-parent">
              <div className="aspect-ratio">Aspect Ratio</div>
              <div className="aspect-ratio-filters">
                <button className={`filter4 ${aspectRatio==='1:1'?'selected':''}`} value='1:1' onClick={(e)=>{handleAspectRatio(e)}}>
                <FontAwesomeIcon className="image-icon" style={{color:'transparent',border:'1px solid black',borderRadius:'3px',padding:'0'}} alt="" size='xs' icon={faSquare} />
                  <div className="photo4">1:1</div>
                </button>
                <button className={`filter5 ${aspectRatio==='3:4'?'selected':''}`}  value='3:4' onClick={(e)=>{handleAspectRatio(e)}}>
                  <div
                    className="empty-ratio-options"
                    style={emptyRatioOptionsStyle}
                  >
                    <div className="empty-photo-options" />
                  </div>
                  <div className="photo5">3:4</div>
                </button>
                <button className={`filter6  ${aspectRatio==='4:3'?'selected':''}`} value='4:3' onClick={(e)=>{handleAspectRatio(e)}}>
                  <div className="div" style={divStyle}>
                    <div className="div1" />
                  </div>
                  <div className="photo6">4:3</div>
                </button>
                <button className={`filter7 ${aspectRatio==='9:16'?'selected':''}`} value='9:16' onClick={(e)=>{handleAspectRatio(e)}}>
                <div className="div3" />
                  <div className="photo7">9:16</div>
                </button>
                <button className={`filter8 ${aspectRatio==='16:9'?'selected':''}`} value='16:9' onClick={(e)=>{handleAspectRatio(e)}}>
                  <div className="div2">
                    <div className="div3" />
                  </div>
                  <div className="photo8">16:9</div>
                </button>
              </div>
            </div>
            <div className="preferences-item" />
            <div className="color-parent">
              <div className="color">Color</div>
              <div className="color-filters">
                <button className={`filter9 ${mood==='black_and_white'?'selected':''}`} value='black_and_white' onClick={(e)=>{handleMood(e)}}>
                  <img className="filter-child" alt="" src="/ellipse-1.svg" />
                  <div className="photo9">B&W</div>
                </button>
                <button className={`filter10 ${mood==='warm'?'selected':''}`} value='warm' onClick={(e)=>{handleMood(e)}}>
                  <img className="filter-item" alt="" src="/ellipse-1-1.svg" />
                  <div className="photo10">Warm</div>
                </button>
                <button className={`filter11 ${mood==='cool'?'selected':''}`} value='cool' onClick={(e)=>{handleMood(e)}}>
                  <img
                    className="filter-inner"
                    loading="lazy"
                    alt=""
                    src="/ellipse-1-2.svg"
                  />
                  <div className="photo11">Cool</div>
                </button>
                <button className={`filter12 ${mood==='vivid'?'selected':''}`} value='vivid' onClick={(e)=>{handleMood(e)}}>
                  <img className="ellipse-icon" alt="" src="/ellipse-1.svg" />
                  <div className="photo12">Vivid</div>
                </button>
                <button className={`filter13 ${mood==='natural'?'selected':''}`} value='natural' onClick={(e)=>{handleMood(e)}}>
                  <img className="filter-child1" alt="" src="/ellipse-1.svg" />
                  <div className="photo13">Natural</div>
                </button>
                <button className={`filter14 ${mood==='bold'?'selected':''}`} value='bold' onClick={(e)=>{handleMood(e)}}>
                  <img className="filter-child2" alt="" src="/ellipse-1.svg" />
                  <div className="photo14">Bold</div>
                </button>
              </div>
            </div>
          </div>
          <div className="view-history-parent" onClick={handleToggleHistory}>
            <div className="view-history" >View History</div>
            <img className="caretright-icon" alt="" src="/caretright.svg" />
          </div>
        </div>
      </div>
      <div className="faqs-parent">
        <div className="faqs">FAQs</div>
        <div className="how-to-use">How to use</div>
      </div>
    </div>
  );
};

export default FrameComponent2;
