import "./FrameComponent.css";

const FrameComponent = (can) => {
  return (
    <div className="frame-parent6">
      <div className="recommended-by-our-experts-parent">
        <h3 className="recommended-by-our">Recommended by our Experts</h3>
        <div className="explore-our-curted">
          Explore our curted gallery of AI images and try the prompts used to
          generate them.
        </div>
      </div>
      <div className="frame-parent7">
        <div className="rectangle-parent">
          <img
            className="frame-item"
            loading="lazy"
            alt=""
            src="/rectangle-1@2x.png"
          />
          <img
            className="frame-inner"
            loading="lazy"
            alt=""
            src="/rectangle-2@2x.png"
          />
          <img
            className="rectangle-icon"
            loading="lazy"
            alt=""
            src="/rectangle-3@2x.png"
          />
        </div>
        <div className="rectangle-group">
          <img
            className="frame-child1"
            loading="lazy"
            alt=""
            src="/rectangle-4@2x.png"
          />
          <div className="frame-parent8">
            <div className="rectangle-container">
              <img
                className="frame-child2"
                loading="lazy"
                alt=""
                src="/rectangle-5@2x.png"
              />
              <img
                className="frame-child3"
                loading="lazy"
                alt=""
                src="/rectangle-6@2x.png"
              />
            </div>
            <div className="rectangle-parent1">
              <img
                className="frame-child4"
                loading="lazy"
                alt=""
                src="/rectangle-7@2x.png"
              />
              <img
                className="frame-child5"
                loading="lazy"
                alt=""
                src="/rectangle-8@2x.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrameComponent;
