import React, { useState } from 'react';
import './login.css';
import { Link, useNavigate } from 'react-router-dom';
import logo from './assets/modelverse-logo.png';

const ForgotPassword = () => {
    const BASE_URL = "https://stock-backend.modelverse.in";

    const navigate = useNavigate();
    const [alertcreate, setAlertCreate] = useState("");
    const [email, setEmail] = useState('');
    const [otp, setOTP] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [step, setStep] = useState(1); // 1 for email verification, 2 for password reset

    const validatePassword = (password) => {
        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return passwordPattern.test(password);
    };

    const handleSendOTP = () => {
        if (email === '') {
            setAlertCreate('Please Enter Your Email');
            return;
        }

        fetch(`${BASE_URL}/send-otp`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: email }),
        })
            .then((response) => response.json())
            .then((json) => {
                if (json.success === true) {
                    setAlertCreate('OTP has been sent to your email.');
                    setStep(2); // Move to the next step (OTP verification)
                } else {
                    setAlertCreate(json.message);
                }
            })
            .catch((error) => {
                console.error('API Error:', error);
            });
    };

    const handleResetPassword = () => {
        if (!validatePassword(newPassword)) {
            setAlertCreate('Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.');
            return;
        }

        if (newPassword !== confirmPassword) {
            setAlertCreate('Passwords do not match');
            return;
        }

        
        fetch(`${BASE_URL}/reset-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: email, otp: otp, newPassword: newPassword }),
        })
            .then((response) => response.json())
            .then((json) => {
                if (json.success === true) {
                    setAlertCreate('Password has been reset successfully.');
                    navigate('/login');
                } else {
                    setAlertCreate(json.message);
                }
            })
            .catch((error) => {
                console.error('API Error:', error);
            });
    };

    return (
        <div className='login-container'>
            <div className='card'>
                <img src={logo} className='logo-image' alt='' />
                <h2 >Forgot Password</h2>
                <h2 style={{fontSize:"16px"}}>{alertcreate}</h2>
                {step === 1 && (
                    <>
                        <input
                            type='email'
                            placeholder='Enter your email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <button className='login-btn' onClick={handleSendOTP}>
                            Send OTP
                        </button>
                    </>
                )}
                {step === 2 && (
                    <>
                        <input
                            type='text'
                            placeholder='Enter OTP'
                            value={otp}
                            onChange={(e) => setOTP(e.target.value)}
                        />
                        <input
                            type='password'
                            placeholder='New Password'
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <input
                            type='password'
                            placeholder='Confirm Password'
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <button className='login-btn' onClick={handleResetPassword}>
                            Reset Password
                        </button>
                    </>
                )}
                <p className='register-link'>
                    Remembered your password? <Link to='/login'>Login</Link>
                </p>
            </div>
        </div>
    );
};

export default ForgotPassword;
