
import React from 'react';
import { CSpinner } from '@coreui/react';
import '@coreui/coreui/dist/css/coreui.min.css';

const Spinner = ({left,top}) => {

    const style = {
        position: 'absolute',
        zIndex: 1001,
        left: left ? left : '60%',
        top: top ? top :  '50%',
        transform: 'translate(-50%, -50%)',
       
      };

      const style1={
        width: '50px',
        height: '50px',
         borderWidth: '5px',
          color: 'white' 
      }
      

  return (
    
    <div className="d-flex justify-content-center "  style={style} >
      <CSpinner  color="dark" style={style1}/>
    </div>
  );
};

export default Spinner;
