import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import FrameComponent3 from "../components/FrameComponent3";
import Extend from "./Extend";
import "./Refine.css";
import { Slider, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import "../components/FrameComponent3.css";

import Switch from '@mui/material/Switch';
import Slider1 from "./Slider";
import Spinner from './Spinner';
const label = { inputProps: { 'aria-label': 'Switch demo' } };

const Refine = ({ handleBackNew1, handleBackResult, imgUrlCard, handleDownload, handleToggle1,handleRefineResult }) => {
  const [refineLoad,setRefineLoad]=useState(false)
  const [locked,setLocked]=useState(false)
  const canvasRef = useRef(null);
  const [maskPoints, setMaskPoints] = useState([]);
  const [maskSize, setMaskSize] = useState(5); // Default mask size
  const [refinedImageUrl, setRefinedImageUrl] = useState('');
  const [imageId, setImageId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [maskingMode, setMaskingMode] = useState(false);
  const [erasingMode, setErasingMode] = useState(false);
  const [isDrawing, setIsDrawing] = useState(false);
  const [editedImageData, setEditedImageData] = useState(null);
  const [slideVal,setSlideVal]=useState(50)
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  const [maskResultUrl, setMaskResultUrl] = useState(null)

  const [popUpTrigger, setPopUpTrigger] = useState(false)
  const [popUpImgUrl, setPopUpImgUrl] = useState("")
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [imageButtonsVisible, setImageButtonsVisible] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [prompt, setPrompt] = useState("");
  const [checked, setChecked] = useState(false);
  const [negativePrompt, setNegativePrompt] = useState("");
  const [brushVal,setBrushVal]=useState(50)
  const [alertcreate,setalertcreate]=useState("");
  const [toggle, setToggle] = useState(false); // for changing it to extend

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if(imgUrlCard){
    const img = new Image();
    img.src = imgUrlCard;

    img.onload = () => {
      const width = img.width;
      const height = img.height;
      setImageDimensions({ width, height });
    };
  }
  }, [imgUrlCard]);

  useEffect(() => {
    if (imgUrlCard) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      const img = new Image();
  
      img.crossOrigin = "anonymous"; // Set crossOrigin attribute to allow CORS
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
  
        // After the image is loaded and drawn on the canvas,
        // you can enable masking mode automatically
        setMaskingMode(true);
      };
  
      img.src = imgUrlCard; // Use the imgUrlCard prop directly
    }
  }, [imgUrlCard]);
    const handleBrushVal=(data)=>{
           setBrushVal(data)
           setMaskSize(data);
         
  }

  const handleSlideVal=(event, newValue)=>{
        setSlideVal(newValue);
  

      if(handleBrushVal){
          handleBrushVal(newValue)
      }

  
  }

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const handleCanvasMouseDown = (event) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = (event.clientX - rect.left) * (canvas.width / rect.width);
    const y = (event.clientY - rect.top) * (canvas.height / rect.height);

    if (maskingMode) {
      setIsDrawing(true); // Start drawing
      drawMask(x, y); // Draw the initial point
      drawPenStructure(x, y); // Draw the pen structure
    } else if (erasingMode) {
      erasePixel(x, y);
      drawEraserStructure(x, y); // Draw the eraser structure
    }
  };
  const handleMaskSizeChange = (event) => {
    setMaskSize(parseInt(event.target.value));
  };
  
  const handleCanvasMouseMove = (event) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = (event.clientX - rect.left) * (canvas.width / rect.width);
    const y = (event.clientY - rect.top) * (canvas.height / rect.height);

    setCursorPosition({ x, y }); // Update cursor position

    if (erasingMode) {
      erasePixel(x, y);
      drawEraserStructure(x, y); // Update the eraser structure
    } else if (maskingMode && isDrawing) {
      drawMask(x, y);
      drawPenStructure(x, y); // Update the pen structure
    }
  };

  const handleCanvasMouseUp = () => {
    setIsDrawing(false); // Stop drawing
  };

  const erasePixel = (x, y) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = 'black'; // Set color to black
    ctx.fillRect(x - 2, y - 2, 5, 5); // Fill pixels in a small area around the clicked point with black
  };

  const drawMask = (x, y) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.strokeStyle = 'white';
    ctx.fillStyle = 'white';
    ctx.lineWidth = maskSize; // Use mask size here
  
    // Draw a line from the previous cursor position to the current cursor position
    ctx.beginPath();
    ctx.moveTo(cursorPosition.x, cursorPosition.y);
    ctx.lineTo(x, y);
    ctx.stroke();
  
    // Draw a filled rectangle at the current cursor position to ensure the selected area is white
    ctx.fillRect(x - maskSize / 2, y - maskSize / 2, maskSize, maskSize);
  
    // Save the current cursor position
    setCursorPosition({ x, y });
  
    // Save the mask points for masking
    setMaskPoints((prevPoints) => [...prevPoints, { x, y }]);
  };
  
  // Adjust the drawPenStructure function to use the mask size
  const drawPenStructure = (x, y) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = 'white'; // Set color to white for the pen structure
    ctx.beginPath();
    ctx.arc(x, y, maskSize / 2, 0, Math.PI * 2); // Use mask size here for radius
    ctx.fill();
  };
  
  // Adjust the drawEraserStructure function to use the mask size
  const drawEraserStructure = (x, y) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(x - maskSize / 2, y - maskSize / 2, maskSize, maskSize); // Use mask size here for width and height
  };

  const handleMaskButtonClick = () => {
    setMaskingMode((prevMode) => !prevMode); // Toggle masking mode
    setErasingMode(false); // Turn off erasing mode
  };

  const handleEraserButtonClick = () => {
    setErasingMode((prevMode) => !prevMode); // Toggle erasing mode
    setMaskingMode(false); // Turn off masking mode
  };

  const handleApplyEdits = () => {
    return new Promise((resolve, reject) => {
      setLoading(true);
      setError(null);
    
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
    
      // Fill the entire canvas with black color
      ctx.fillStyle = 'black';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    
      // Load image onto canvas
      const img = new Image();
      img.onload = () => {
        // Apply mask
        if (maskPoints.length > 0) {
          ctx.globalCompositeOperation = 'destination-out'; // Set composite mode to delete pixels
          ctx.strokeStyle = 'white'; // Set pen color to white for masking
          ctx.lineWidth = maskSize; // Set pen thickness to mask size
    
          ctx.beginPath();
          ctx.moveTo(maskPoints[0].x, maskPoints[0].y);
          for (let i = 1; i < maskPoints.length; i++) {
            ctx.lineTo(maskPoints[i].x, maskPoints[i].y);
          }
          ctx.stroke();
        }
    
        // Fill the masked area with white color
        ctx.globalCompositeOperation = 'destination-over'; // Set composite mode to draw pixels behind existing content
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
    
        // Convert canvas to image and store in state
        canvas.toBlob((blob) => {
          setEditedImageData(blob);
          setLoading(false);
          resolve(); // Resolve the promise after setting the edited image data
        }, 'image/jpeg');
      };
    
      img.src = imgUrlCard; // Set the source of the image
    });
  };
  const canvasToBase64 = () => {
    const canvas = canvasRef.current;
    return canvas.toDataURL('image/jpeg');
  };
  const handleSubmitMask = async () => {

    if(prompt.length<=2)
      {
        setalertcreate('Please enter aleast three characters !')
        return ;
      }
      
      setRefineLoad(true)
      setLocked(true)
    try {
      setalertcreate("")
      const email=localStorage.getItem("email")||false;
      setLoading(true);
      setError(null);
  
      // Debug log before applying edits
     
      await handleApplyEdits();
  
      const canvas = canvasRef.current;
  
      // Check if canvas is correctly referenced
      if (!canvas) {
        throw new Error("Canvas element not found");
      }
  
      // Debug log after applying edits and before converting to data URL
     
      const maskDataURL = canvas.toDataURL('image/jpeg');
      
  
      // Ensure the mask data URL is valid
      if (!maskDataURL.startsWith('data:image/jpeg')) {
        throw new Error("Invalid mask data URL");
      }
  
      // Send mask image data URL to your backend endpoint
      try {
        if(email){
          const response = await axios.post('https://stock-backend.modelverse.in/maskimage', {
            id: imageId,
            img_url: selectedImageUrl,
            mask: maskDataURL,
            prompt: prompt,
            negativePrompt: negativePrompt,
            imgUrlCard,
            email:email
          });

          setLocked(false)
      
          
          handleRefineResult(response.data)
          
          
          setLoading(false);
          setRefineLoad(false)
        }
        else{
          handlelogut();
        }
        }
         catch (error) {
          function myFunction() {
            window.location.reload();
          }
          
          setTimeout(myFunction, 5000);

          if (error.response && error.response.status === 401) {
            // Unauthorized access, perform logout action
          handlelogut();
        }
        if (error.response && error.response.status === 402) {
          // Unauthorized access, perform logout action
          setalertcreate("Payment required !");
        }
        // console.error('Error submitting mask image:', error);
        setError('Error submitting mask image. Please try again.');
        // setLoading(false);
        // setRefineLoad(false)
      }
    } catch (error) {
      // console.error('Error:', error);
      setError('Error converting canvas to image data. Please try again.');
      function myFunction() {
        window.location.reload();
      }
      
      setTimeout(myFunction, 5000);
      // setLoading(false);
    }
   
  };
  const handlelogut=()=>{
    localStorage.clear();
    window.location.reload();
  }

  const handleToggle = () => {
    setToggle(!toggle);
   
  };

  const handleBackNew = () => {
    setToggle(!toggle);
    handleBackResult();
  };

  // Function to handle mask download
  const handleDownloadMask = () => {
    const canvas = canvasRef.current;
    canvas.toBlob((blob) => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'mask_image.jpg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }, 'image/jpeg');
  };

  return (
    <>
      {!toggle && (
        <div className="refine">
          <div className="frame-container">
            <button className="caretleft-parent">
              <img className="caretleft-icon" alt="" src="/caretleft.svg" />
              {handleBackNew1 ? (
                <div className="back-to-result" onClick={handleBackNew1}>Back to result</div>
              ) : (
                <div className="back-to-result" onClick={handleBackResult}>Back to result</div>
              )}
            </button>
            <div className="frame-parent9">
      <div className="frame-parent10">
        <div className="refine-parent">
          <div className="refine5">Refine</div>
          <div className="brush-over-any">
            Brush over any part of the image to easily replace or remove
            selected elements.
          </div>
        </div>
        <div className="filter-parent">
          <button className="filter15">
            <img className="swap-icon" alt="" src="/swap.svg" />
            <div className="photo16">Replace BG</div>
          </button>
          <button className="filter16">
            <img className="eraser-icon" alt="" src="/eraser.svg" />
            <div className="photo17">Remove</div>
          </button>
        </div>
        <h2 className='brush-over-any'>{alertcreate}</h2>
      </div>
      <div className="brush-parent">
        <div className="brush">Brush</div>
        <div className="size-parent">
          <div className="size">Size</div>
          {/* <div className="group-div">
            <div className="rectangle-div" />
            <div className="rectangle-parent2">
              <div className="frame-child6" />
              <div className="frame-child7" />
            </div>
          </div> */}
          <div style={{ width: 300, margin: '20px auto' }}className='newslider'>
      {/* <Typography id="zoom-slider" gutterBottom>
        Zoom
      </Typography> */}
      <Slider
        value={maskSize} 
        onChange={handleMaskSizeChange}
        defaultValue={50}
        aria-label="Default"
        valueLabelDisplay="auto"
        sx={{
          '& .MuiSlider-thumb': {
            width: 20,
            height: 20,
            backgroundColor: '#ffffff',
          },
          '& .MuiSlider-track': {
            height: 10,
            backgroundColor: '#537FEF',
          },
          '& .MuiSlider-rail': {
            height: 10,
          },
        }}
      />
    </div>
        </div>
      </div>
      <div className="prompt-required-parent">
        <div className="prompt-required">
          <span className="prompt1">Prompt (</span>
          <span className="required">required</span>
          <span className="span">)</span>
        </div>
        <div className="prompt-container">
          <textarea
            className="prompt-container-child"
            placeholder="Describe image you want to generate"
            rows={6}
            cols={15}
            onChange={(e) => setPrompt(e.target.value)}
          />
          <div className="toggle-container">
            {/* <div className="toggle">
              <div className="toggle-child" />
              
            </div> */}
            <Switch {...label} checked={checked} onChange={handleChange} />
            <div className="negative-prompt-wrapper" style={{marginTop:'6px' , display:!checked?'flex':'none' }}>
              <div className="negative-prompt">Negative Prompt</div>
            </div>
            <div className="info-container" style={{marginTop:'6px' , display:!checked?'flex':'none' }}>
              <img
                className="info-icon"
                loading="lazy"
                alt=""
                src="/info.svg"
              />
            </div>
            <input style={{visibility:checked?'visible':'hidden'}}  className='inp-1' placeholder='Describe that you dont want in the image' value={negativePrompt} onChange={(e) => setNegativePrompt(e.target.value)}/>

          </div>
        </div>
      
       <button className="generate-container" onClick={handleSubmitMask} disabled={locked}>
          <img className="sparkle-icon1" alt="" src="/sparkle1.svg" />
          <div className="generate1">Generate</div>
        </button>
      </div>
    </div>
          </div>
          <div className="tabs-container-parent">
            <div className="tabs-container">
              <div className="tab-parent">
                <div className="tab">
                  <div className="refine1">Refine</div>
                </div>
                <div className="tab1">
                  {handleToggle1 ? (
                    <div className="refine2" onClick={handleToggle1}>Extend</div>
                  ) : (
                    <div className="refine2" onClick={handleToggle}>Extend</div>
                  )}
                </div>
              </div>
            </div>
             {refineLoad && <Spinner left="54%" top="40%"/>}
            { <div className="image-container" style={{visibility : !refineLoad ? 'visible':'hidden' }}>
              <canvas
                ref={canvasRef}
                className="image"
                style={{height:imageDimensions.height!=0?`${imageDimensions.height}px`:'30.563rem',width:imageDimensions.width!=0?`${imageDimensions.width}px`:''}}
                onMouseDown={handleCanvasMouseDown}
                onMouseMove={handleCanvasMouseMove}
                onMouseUp={handleCanvasMouseUp}
                alt="image"
              />
                  <div className="mask-size-lever">
 
  </div>
              <div className="filter-container" style={{marginLeft: '50px'}}>
                <div className="filter" onClick={() => { handleDownload(imgUrlCard) }}>
                  <div className="arrowdown-wrapper">
                    <img className="arrowdown-icon" alt="" src="/arrowdown.svg" />
                  </div>
                  <div className="photo" onClick={handleDownloadMask}>Download</div>
                </div>
              </div>
              <div className="toolbar">
                {/* <button className="mask-button" onClick={handleMaskButtonClick}>Mask</button> */}
                {/* <button className="eraser-button" onClick={handleEraserButtonClick}>Erase</button> */}
                {/* <button className="apply-edits-button" onClick={handleApplyEdits}>Apply Edits</button> */}
                {/* <button className="submit-mask-button" onClick={handleSubmitMask}>Submit Mask</button> */}
                {/* <button className="download-mask-button" onClick={handleDownloadMask}>Download Mask</button> */}
              </div>
            </div>}
          </div>
        </div>
      )}
      {toggle && (
        <Extend
          handleBackNew={handleBackNew}
          handleToggle={handleToggle}
          handleBackResult={handleBackResult}
          imgUrlCard={imgUrlCard}
          handleDownload={handleDownload}
        />
      )}
    </>
  );
};

export default Refine;
