import { useEffect } from "react";
import { Routes, Route, useNavigationType, useLocation } from "react-router-dom";
import Default from "./pages/Default";
import SearchResult from "./pages/SearchResult";
import Refine from "./pages/Refine";
import Extend from "./pages/Extend";
import History from "./pages/History";
import Login from "./pages/login";
import Signup from "./pages/signup";
import ProtectedRoute from "./pages/protect";
import Forgot from "./pages/forgot";
function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "Home";
        metaDescription = "Welcome to the homepage";
        break;
      case "/search-result":
        title = "Search Result";
        metaDescription = "View search results";
        break;
      case "/refine":
        title = "Refine";
        metaDescription = "Refine your search";
        break;
      case "/extend":
        title = "Extend";
        metaDescription = "Extend your search";
        break;
      case "/history":
        title = "History";
        metaDescription = "View your history";
        break;
      case "/login":
        title = "Login";
        metaDescription = "Login to your account";
        break;
      default:
        title = "Modelverse";
        metaDescription = "Modelverse application";
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector('head > meta[name="description"]');
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/reset" element={<Forgot />} />

      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<SearchResult />} />
        <Route path="/history" element={<History />} />
      </Route>
    </Routes>
  );
}

export default App;
