import Spinner from "../pages/Spinner";
import FrameComponent from "./FrameComponent";
import FrameComponent1 from "./FrameComponent1";
import Image1 from "./Image1";
import ImageCardLoop from "./ImageCard";
import "./Prompt1.css";


const Prompt1 = ({handlePopUpTrigger,imageDimensions,handleToggleHistory,loading,locked,images,mood,aspectRatio,mediaType ,sendDataToServer,prompt,setPrompt,toggle,handleDownload,handleToggleExtend,handleToggleRefine ,handleImgUrlCard,alertcreate}) => {
  return (
   
    <section className="prompt">
      <FrameComponent1 frameTextareaBorder="1px solid var(--black-2)" handleToggleHistory={handleToggleHistory} locked={locked} mediaType={mediaType} aspectRatio={aspectRatio} mood={mood} sendDataToServer={sendDataToServer} prompt={prompt}  setPrompt={setPrompt} alertcreate={alertcreate}/>
      {toggle&& <div className="result">
        <div className="result-container">
          <h3 className="showing-result">Showing Result</h3>
          <div className="prompt-explore-our">
            Prompt: {!loading && images && images.length>0 ? images[0].prompt :'Explore our curted gallery of AI images and try the prompts used to generate them.'}
          </div>
        </div>
        <div className="gallery">
        
          
          {loading &&<Spinner/>}
          {!loading &&<ImageCardLoop handlePopUpTrigger={handlePopUpTrigger} imageDimensions={imageDimensions}  images={images} handleImgUrlCard={handleImgUrlCard} handleDownload={handleDownload} handleToggleExtend={handleToggleExtend}  handleToggleRefine={handleToggleRefine} />}
        </div>
      </div> }
      

     {!toggle && <FrameComponent/>}
    </section>
  );
};

export default Prompt1;
