import { useEffect, useMemo,useState } from "react";
import "./FrameComponent1.css";
import axios from "axios";


const FrameComponent1 = ({locked, handleToggleHistory ,frameTextareaBorder,mood,aspectRatio,mediaType ,sendDataToServer,prompt,setPrompt, alertcreate}) => {
  const frameTextareaStyle = useMemo(() => {
    return {
      border: frameTextareaBorder,
    };
  }, [frameTextareaBorder]);

  const [isProfileVisible, setIsProfileVisible] = useState(false);
  const [credits, setCredits] = useState(0);
  const email = localStorage.getItem("email")||false;

  const toggleProfile = () => {
    setIsProfileVisible(!isProfileVisible);
  };

  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const fetchCredits = async (email) => {
    try {
      if(email){
      const response = await axios.post("https://stock-backend.modelverse.in/credits", { email });
      // console.log(response.data.credits);
      setCredits(response.data.credits); // Ensure you're setting the correct data
      }else{
        handlelogut()
      }
    } catch (error) {
      // console.error('Error fetching credits:', error);
    }
  };

  const username = localStorage.getItem("userName")||"user";
useEffect(()=>{
  fetchCredits(email)
},[])
  return (
    <div className="write-prompt-parent">
      <div className="profile">
        <button onClick={toggleProfile} className="profile-button">
          {username[0].toUpperCase()}
        </button>
        {isProfileVisible && (
          <div className="profile-dropdown">
            <p><strong>Username:</strong>{username}</p>
            <p><strong>Email:</strong>{email}</p>
            <p><strong>Credits:</strong>{credits}</p>
            <div className="gallaLog">
            <button onClick={handleToggleHistory} className="logout-button">Gallery</button>
            <button onClick={logout} className="logout-button">Logout</button>
            </div>
          </div>
        )}
      </div>
      <textarea
        className="frame-child"
        placeholder="Describe image you want to generate"
        rows={7}
        cols={49}
        style={frameTextareaStyle}
        value={prompt} onChange={(e) => setPrompt(e.target.value)}
      />
      <h2 style={{marginRight:"42vw"}}>{alertcreate}</h2>
      <div className="frame-parent4">
        <div className="image-options-parent">
          <button className="image-options">
            <img className="image-icon1" alt="" src="/image-1.svg" />
            <div className="photo15">{mediaType}</div>
          </button>
          <button className="square-11-wrapper">
            <div className="square-11">{aspectRatio}</div>
          </button>
          <button className="shape-parent">
            <div className="shape" />
            <div className="all">{mood}</div>
          </button>
        </div>
        <div className="frame-parent5">
          <div className="generation-remaining-wrapper">
            <div className="generation-remaining">{credits} generation remaining</div>
          </div>
          <button className="sparkle-parent" onClick={()=>{sendDataToServer()}} disabled={locked}>
            <img className="sparkle-icon" alt="" src="/sparkle.svg" />
            <div className="generate" >Generate</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default FrameComponent1;
